<template>
  <v-container fluid>
    <v-sheet class="d-flex mx-auto py-4" min-height="80vh" :width="$vuetify.breakpoint.mobile ? '92vw' : '80vw'">
      <v-row no-gutters class="py-4">
        <v-col cols="12" md="3" class="px-2">
          <div v-if="modulePlanList" class="d-flex flex-column" style="height: 74vh">
            <div>
              <v-row no-gutters justify="center">
                <v-icon color="logo_Red" size="125"> mdi-clipboard-list-outline </v-icon>
              </v-row>
              <v-row no-gutters justify="center" class="mt-4">
                <p class="text-h5 mb-0 text--secondary font-weight-medium">Rediger Lektionsplan</p>
              </v-row>
              <v-row justify="center" no-gutters>
                <v-col cols="auto">
                  <v-switch v-model="showDeleted" label="Vis slettede" />
                </v-col>
              </v-row>
              <v-row no-gutters justify="center" class="mb-4">
                <editable-module-list :rules="[rules.required]" textVal="Tilføj" label="Lektionsplan" :edit="false" @input="updateModulePlan" />
              </v-row>
            </div>
            <div style="overflow-y: auto" class="px-2">
              <div v-for="(item, index) in sortedModulePlanList" :key="index" class="mb-4 pt-1">
                <v-hover v-slot="{ hover }">
                  <v-card
                    :elevation="hover ? 6 : 2"
                    :class="{
                      'grey lighten-2': hover,
                      activeButton: selectedModulePlan == item,
                      'white--text': selectedModulePlan == item,
                      warning: item.isDisabled,
                    }"
                    class="d-flex justify-center align-center background"
                    height="75"
                    @click="setActiveModulePlan(item)"
                  >
                    <p class="pa-0 ma-0">
                      <span class="text-uppercase">{{ item.name }}</span>
                      <span v-if="item.state !== 1">(ikke frigivet)</span>
                    </p>
                  </v-card>
                </v-hover>
              </div>
            </div>
          </div>
        </v-col>
        <v-divider vertical inset />
        <v-col cols="12" md="4" class="text-center px-2">
          <div class="d-flex flex-column" style="max-height: 74vh">
            <v-row class="pt-3 pb-1" no-gutters>
              <v-col>
                <div v-if="selectedModulePlan" class="d-flex pl-3">
                  <span class="text-h6 text--secondary font-weight-medium my-auto"> Lektionsplan for {{ selectedModulePlan.name }}</span>
                  <editable-module-list
                    v-if="selectedModulePlan"
                    :deleteable="true"
                    :rules="[rules.required]"
                    :value="selectedModulePlan.name"
                    @input="updateModulePlan"
                    @delete="deleteModulePlan"
                    class="mr-0"
                  />
                </div>
                <p v-else class="text-h6 text--secondary font-weight-medium my-auto">Lektionsplan</p>
              </v-col>
              <v-col v-if="selectedModulePlan" cols="auto">
                <editable-module v-if="!selectedModulePlanIsReleased" :rules="[rules.required]" :edit="false" @input="updateModule" />
              </v-col>
            </v-row>
            <div v-if="selectedModulePlan" class="mb-2 mx-3 text-left text--secondary">
              <strong>{{ theoryDuration }}</strong
              >x45 min teori og <strong>{{ practicalDuration }}</strong
              >x45 min praksis
            </div>
            <v-alert type="warning" v-if="selectedModulePlan && selectedModulePlan.isDisabled"> Lektionsplanen er slettet </v-alert>
            <v-row no-gutters v-if="selectedModulePlan && !selectedModulePlanIsReleased">
              <v-col>
                <v-switch v-model="showDeletedModules" @click="getModuleList" label="Vis slettede moduler" />
                <r-btn class="mx-2 mb-2" block action @click="releaseActiveModulePlan">Afslut og frigiv</r-btn>
              </v-col>
            </v-row>
            <div v-if="selectedModulePlan" style="overflow-y: auto" class="px-2">
              <div v-for="(item, index) in sortedModuleList" :key="index" class="mb-4">
                <v-hover v-slot="{ hover }">
                  <v-card
                    @click="
                      if (!selectedModulePlanIsReleased) {
                        setActiveModule(item);
                      }
                    "
                    :disabled="selectedModulePlanIsReleased"
                    elevation="1"
                  >
                    <v-card-title
                      class="d-block pb-1 background"
                      :class="{
                        'grey lighten-1': hover,
                        'grey lighten-2': !hover,
                        activeButton: selectedModule == item,
                        'white--text': selectedModule == item,
                      }"
                    >
                      <v-row no-gutters>
                        <v-col cols="auto" class="d-flex text-left">
                          <p class="my-auto">{{ item.name }}</p>
                          <editable-module
                            v-if="selectedModule == item"
                            dark
                            :rules="[rules.required]"
                            :moduleData="selectedModule"
                            :edit="true"
                            @input="updateModule"
                            @remove="deleteModule"
                          />
                        </v-col>
                        <v-spacer></v-spacer>

                        <v-col class="text-right">
                          <p class="text-body-2 my-auto">
                            {{ parseLessonType(item.lessonType) }}<br />
                            <span v-if="item.minimumDuration">{{ item.minimumDuration }}x45 min</span>
                          </p>
                        </v-col>
                      </v-row>
                      <p class="text-subtitle-1 text-left">{{ item.description }}</p>
                    </v-card-title>
                    <v-card-text class="mt-3">
                      <v-row v-for="(subitem, index) in isRepetitiveEducationPin(item)" :key="index" class="my-1" no-gutters>
                        <v-col class="text-left">
                          <p class="ma-0">{{ subitem.name }}</p>
                        </v-col>
                        <v-col cols="2">
                          <r-btn @click.stop="removeLessonFromModule(subitem)" v-show="selectedModule == item" plain x-small color="error">
                            Fjern
                          </r-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </div>
            </div>
          </div>
        </v-col>
        <v-divider vertical inset />
        <v-col cols="12" md="5" class="text-center px-2">
          <div class="d-flex flex-column" style="height: 74vh">
            <div class="d-flex">
              <v-col class="text-left">
                <p class="text-h6 text--secondary font-weight-medium mb-0">Uddannelsesplan</p>
              </v-col>
              <v-col class="d-flex justify-end">
                <v-checkbox class="my-0" v-model="showOnlyUnused" hide-details="auto" :disabled="!selectedModulePlan" label="Vis kun ikke placeret" />
              </v-col>
            </div>
            <div
              v-if="educationPlan.educationPlanLessons && selectedModulePlan && !selectedModulePlanIsReleased"
              style="overflow-y: auto"
              class="px-2"
            >
              <div v-for="(item, index) in getLessonList" :key="index" class="d-flex mb-4">
                <v-col class="text-left pa-0">
                  <p class="mb-0">
                    {{ item.name }}
                  </p>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <r-btn v-if="selectedModule" class="text-center" @click="addLessonToModule(item)" plain x-small> tilføj </r-btn>
                </v-col>
              </div>
            </div>
            <v-alert type="warning" v-else-if="selectedModulePlan && selectedModulePlanIsReleased">
              Den valgte lektionsplan er allerede frigivet til brug og kan derefter ikke redigeres for at sikre at historiske data bibeholdes.<br />

              Tilføj en ny lektionsplan for at foretage ændringer.
            </v-alert>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
    <confirmation-dialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import EditableModule from '@/components/EditableModule.vue';
import EditableModuleList from '@/components/EditableModuleList.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';

export default {
  name: 'ModulePlan',
  components: { EditableModule, EditableModuleList, ConfirmationDialog },
  data: () => ({
    isEditingModule: false,
    isValidModule: false,
    isLoading: false,
    isLoadingModuleList: false,
    isLoadingEducationPlan: false,
    isSaving: false,
    showDeleted: false,
    showDeletedModules: false,
    modulePlanList: [],
    moduleList: [],
    educationPlan: [],
    selectedModulePlan: null,
    selectedModule: null,
    showOnlyUnused: false,
    newModulePlan: '',
    rules: {
      required: value => !!value || 'Skal udfyldes',
    },
  }),
  watch: {
    currentOrgId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getModulePlanList();
          this.moduleList = [];
          this.educationPlan = [];
        }
      },
    },
    selectedModulePlan: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getModuleList();
        }
      },
    },
  },
  computed: {
    ...mapGetters(['globalAlert', 'darkMode', 'currentOrgId']),
    getLessonList() {
      //I need any lesson that is not currently part of a module in the selected module plan
      let flattenedList = _.flatten(this.moduleList.map(y => y.educationPlanLessons)).map(z => z.id);
      return this.educationPlan.educationPlanLessons.filter(x => !this.showOnlyUnused || !flattenedList.includes(x.id));
    },
    sortedModuleList() {
      //return new array with original the moduleList elements sorted by natural language.
      return [...this.moduleList].sort((a, b) => a.order - b.order);
    },
    sortedModulePlanList() {
      return this.showDeleted ? this.modulePlanList : this.modulePlanList.filter(x => !x.isDisabled);
    },
    selectedModulePlanIsReleased() {
      return this.selectedModulePlan?.state === 1;
    },
    theoryDuration() {
      return _.sumBy(
        this.sortedModuleList.filter(x => x.lessonType === 1),
        x => x.minimumDuration,
      );
    },
    practicalDuration() {
      return _.sumBy(
        this.sortedModuleList.filter(x => x.lessonType === 2),
        x => x.minimumDuration,
      );
    },
  },
  methods: {
    isRepetitiveEducationPin(module) {
      let educationPins = [];

      let placedEducationPins = _.flatten(this.moduleList.filter(x => x.order < module.order).map(y => y.educationPlanLessons)).map(
        z => z.educationModuleNumber,
      );
      let moduleLessonTypes = this.moduleList.filter(x => x.order < module.order).map(y => y.lessonType);

      let arr = placedEducationPins.map(function (x, i) {
        return { pin: x, lessonType: moduleLessonTypes[i] };
      });

      module.educationPlanLessons.forEach(pin => {
        if (arr.map(x => x.pin).includes(pin.educationModuleNumber) && arr.map(x => x.lessonType).includes(module.lessonType)) {
          educationPins.push({ ...pin, name: `${pin.name} - Repetition` });
        } else {
          educationPins.push(pin);
        }
      });

      return educationPins;
    },
    async getModulePlanList() {
      if (this.currentOrgId) {
        this.isLoading = true;
        this.modulePlanList = await apiService.getModulePlanList(this.currentOrgId, true);

        if (this.newModulePlan) {
          this.setActiveModulePlan(this.modulePlanList.filter(x => x.id == this.newModulePlan).shift());
        }
        this.isLoading = false;
      }
    },
    async getModuleList() {
      if (this.selectedModulePlan) {
        this.isLoadingModuleList = true;
        this.moduleList = await apiService.getModulesByModulePlan(this.selectedModulePlan.id, this.showDeletedModules);
        this.isLoadingModuleList = false;
      }
    },
    async getEducationPlan() {
      this.isLoadingEducationPlan = true;
      this.educationPlan = await apiService.getEducationPlan(this.selectedModulePlan.educationPlanType);
      this.isLoadingEducationPlan = false;
    },
    parseLessonType(type) {
      switch (type) {
        case 1:
          return 'Teori';
        case 2:
          return 'Praktisk';
        default:
          return 'None';
      }
    },
    async addLessonToModule(item) {
      this.isLoadingModuleList = true;
      this.isLoadingEducationPlan = true;

      this.selectedModule.educationPlanLessons.push(item);
      await apiService.updateModule(this.selectedModule);

      this.isLoadingModuleList = false;
      this.isLoadingEducationPlan = false;

      this.sortModuleContent();
    },
    async updateModule(moduleData) {
      this.isLoadingModuleList = true;
      if (moduleData.isEditing) {
        this.selectedModule.name = moduleData.name;
        this.selectedModule.description = moduleData.description;
        this.selectedModule.lessonType = moduleData.lessonType;
        this.selectedModule.minimumDuration = moduleData.minimumDuration;
        this.selectedModule.flags = moduleData.flags;
        await apiService.updateModule(this.selectedModule);
      } else {
        await apiService.updateModule({
          ...moduleData,
          organizationId: this.selectedModulePlan.organizationId,
          moduleListId: this.selectedModulePlan.id,
        });
      }
      await this.getModuleList();
      this.setActiveModule(this.selectedModule, true), (this.isLoadingModuleList = false);
    },
    async deleteModule() {
      await apiService.deleteModule(this.selectedModule.id);
      this.getModuleList();
    },
    async updateModulePlan(editValues, isEdit) {
      if (this.selectedModulePlan && isEdit) {
        await apiService.updateModulePlan({ ...this.selectedModulePlan, name: editValues.name });
      } else {
        this.newModulePlan = await apiService.updateModulePlan({
          name: editValues.name,
          educationPlanType: editValues.category,
          organizationId: this.currentOrgId,
          modules: editValues.modules,
        });
      }
      this.clear();
      this.getModulePlanList();
    },
    async deleteModulePlan() {
      await apiService.deleteModulePlan(this.selectedModulePlan.id);
      this.clear();
      this.getModulePlanList();
    },
    async removeLessonFromModule(item) {
      this.isLoadingModuleList = true;
      this.isLoadingEducationPlan = true;
      this.selectedModule.educationPlanLessons.splice(
        this.selectedModule.educationPlanLessons.findIndex(x => x.id == item.id),
        1,
      );
      await apiService.updateModule(this.selectedModule).finally(() => {
        this.isLoadingModuleList = false;
        this.isLoadingEducationPlan = false;
      });
    },
    setActiveModulePlan(item) {
      this.moduleList = [];
      item == this.selectedModulePlan ? (this.selectedModulePlan = null) : (this.selectedModulePlan = item);
      this.educationPlan = {};
      if (this.selectedModulePlan) this.getEducationPlan();
    },
    setActiveModule(item, isEditing) {
      if (isEditing) {
        this.selectedModule = this.moduleList.find(x => {
          return x.id == item.id;
        });
      } else {
        this.selectedModule = this.selectedModule == item ? null : item;
      }
    },
    sortModuleContent() {
      this.selectedModule.educationPlanLessons = this.selectedModule.educationPlanLessons.sort(function (a, b) {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });
    },
    async releaseActiveModulePlan() {
      const response = await this.$refs.confirmationDialog.showDialog({
        title: 'Afslut/frigiv lektionsplan',
        body: `Bekræft at du gerne vil frigive lektionsplanen ${this.selectedModulePlan.name}?
        Når lektionsplanen bliver frigivet, så kan den tildeles elever og benyttes - men den kan ikke længere redigeres.
        Derfor skal du sikre dig at lektionsplanen er korrekt inden at den frigives.`,
        okBtn: 'Frigiv',
      });

      this.$refs.confirmationDialog.hideDialog();

      if (response) {
        await apiService.releaseModulePlan(this.selectedModulePlan.id);

        this.clear();
        this.getModulePlanList();
      }
    },
    clear() {
      this.selectedModulePlan = null;
      this.moduleList = [];
      this.educationPlan = {};
    },
  },
  created() {
    this.getModulePlanList();
  },
};
</script>

<style>
.ScrollableDiv {
  height: 68vh;
  width: auto;
  overflow-y: auto;
}
</style>
