<template>
  <span>
    <v-menu v-model="isEditing" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <r-btn plain small v-bind="attrs" v-on="on" @click="beginEdit" :dark="dark">
          <v-icon>{{ edit ? 'mdi-pencil' : 'mdi-plus-circle-outline' }}</v-icon>
          <p class="ma-0 pa-0">{{ edit ? 'Rediger' : 'Tilføj' }}</p>
        </r-btn>
      </template>
      <v-card class="pa-2" width="300">
        <v-form ref="form">
          <v-text-field outlined dense hide-details="auto" class="ma-2 mb-0" label="Navn" v-bind="$attrs" :autofocus="!edit" v-model="name" />
          <v-select outlined dense hide-details="auto" class="ma-2 mb-0" label="Modul type" v-bind="$attrs" :items="lessonTypes" v-model="lessonType" />
          <v-text-field outlined dense hide-details="auto" class="ma-2 mb-0" label="Minimum længde" suffix="perioder af 45 minutter" v-model.number="duration" :rules="[rules.duration]" />
          <v-slider class="mx-2" min="1" max="5" v-model="duration" ticks="always" tick-size="1" :tick-labels="[1, 2, 3, 4, 5]" />
          <v-textarea outlined dense hide-details="auto" class="ma-2 mb-0" label="Beskrivelse" v-bind="$attrs" :rules="[]" v-model="description" />
          <v-checkbox v-model="disableBooking" label="Dette modul skal ikke kunne bookes i kalenderen" class="mx-2" />
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12" v-if="edit" class="mb-2">
                <r-btn delete @click="remove" block> Slet </r-btn>
              </v-col>
              <v-col cols="12" md="6" class="pr-1">
                <r-btn block @click="isEditing = false"> Annuller </r-btn>
              </v-col>
              <v-col cols="12" md="6" class="pl-1">
                <r-btn block creation @click="saveValue"> Gem </r-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-menu>
    <confirmation-dialog ref="confirmationDialogRef" />
  </span>
</template>
<script>
export default {
  name: 'editable-module',
  props: {
    value: String,
    label: {
      type: String,
      default: '',
    },
    edit: Boolean,
    moduleData: Object,
    dark: Boolean,
  },
  computed: {
    disableBooking: {
      get() {
        return (this.flags & 1) === 1;
      },
      set(val) {
        if (val) {
          this.flags |= 1;
        } else {
          this.flags &= ~1;
        }
      }
    }
  },
  data: () => ({
    name: null,
    description: null,
    lessonType: null,
    isEditing: false,
    duration: 2,
    flags: 0,
    lessonTypes: [
      { text: 'Teori', value: 1 },
      { text: 'Praktisk', value: 2 },
    ],
    rules: {
      duration: (val) => val >= 1 && val <= 5 ? true : 'Længde skal være mindst 1 og højst 5 perioder'
    }
  }),
  methods: {
    beginEdit() {
      if (this.moduleData) {
        this.name = this.moduleData.name;
        this.description = this.moduleData.description;
        this.lessonType = this.moduleData.lessonType;
        this.duration = this.moduleData.minimumDuration;
        this.flags = this.moduleData.flags;
      } else {
        this.name = null;
        this.description = null;
        this.lessonType = 1;
        this.duration = 2;
        this.flags = 0;
      }

      this.isEditing = true;
    },
    saveValue() {
      if (this.$refs.form.validate()) {
        this.$emit('input', { name: this.name, description: this.description, lessonType: this.lessonType, isEditing: this.edit, minimumDuration: this.duration, flags: this.flags });
        this.isEditing = false;
      }
    },
    async remove() {
      let dialogResponse = await this.$refs.confirmationDialogRef.showDialog({
        title: 'Slet modul',
        studentName: this.name,
        body: `Ønsker du at slette dette modul?`,
        okBtn: 'Slet',
      });
      if (dialogResponse) {
        this.$emit('remove');
        this.isEditing = false;
      }
      this.$refs.confirmationDialogRef.hideDialog();
    },
  },
};
</script>