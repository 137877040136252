<template>
  <span>
    <v-menu v-model="isEditing" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <r-btn small plain v-bind="attrs" v-on="on" @click="beginEdit">
          <v-icon v-if="edit">mdi-pencil</v-icon>
          <v-icon v-else>mdi-plus-circle-outline</v-icon>
          {{ textVal }}
        </r-btn>
      </template>
      <v-card width="300" class="py-2">
        <v-form ref="form">
          <v-text-field
            class="ma-2 mb-0"
            v-bind="$attrs"
            outlined
            dense
            hide-details="auto"
            v-model="editValues.name"
            :label="label"
            @keypress.enter.prevent="saveValue"
          />
          <v-select
            v-if="!edit"
            v-model="editValues.category"
            :items="licenseCategories"
            v-bind="$attrs"
            label="Kategori"
            class="ma-2 mb-0"
            outlined
            dense
            hide-details="auto"
          >
          </v-select>
          <v-text-field
            v-if="!edit"
            v-model="moduleCount"
            v-bind="$attrs"
            label="Antal moduler"
            class="ma-2 mb-0"
            outlined
            dense
            hide-details="auto"
          />
          <div v-if="!edit" class="ma-2 scrollable">
            <v-row no-gutters v-for="(mod, index) in editValues.modules" :key="index" align="center">
              <v-col>
                {{ mod.name }}
              </v-col>
              <v-col cols="auto">
                <v-radio-group v-model="mod.lessonType" row hide-details dense class="ma-0" :rules="[item => (item ? true : false)]">
                  <v-radio :value="1" label="Teori" />
                  <v-radio :value="2" label="Praktisk" />
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12" v-if="edit && deleteable" @click="deleteObject()" class="mb-2">
                <r-btn delete block> Slet </r-btn>
              </v-col>
              <v-col cols="12" md="6" class="pr-1">
                <r-btn block @click="isEditing = false" soft> Annuller </r-btn>
              </v-col>
              <v-col cols="12" md="6" class="pl-1">
                <r-btn creation block @click="saveValue"> {{ edit ? 'Gem' : 'Tilføj' }} </r-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-menu>
    <confirmation-dialog ref="confirmationDialogRef" />
  </span>
</template>
<script>
export default {
  name: 'editable-module-list',
  props: {
    value: String,
    textVal: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: true,
    },
    deleteable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isEditing: false,
    editValues: {
      name: null,
      category: null,
      modules: [],
    },
    licenseCategories: [
      {
        text: 'B',
        value: 1,
      },
      {
        text: 'A1, A2 og A',
        value: 2,
      },
    ],
  }),
  computed: {
    moduleCount: {
      get() {
        return this.editValues.modules.length;
      },
      set(val) {
        if (val < this.moduleCount) {
          this.editValues.modules.splice(val, this.moduleCount - val);
        } else {
          for (let i = this.moduleCount; i < val; i++) {
            this.editValues.modules.push({
              name: `Modul ${i + 1}`,
              lessonType: 0,
            });
          }
        }
      },
    },
  },
  methods: {
    beginEdit() {
      this.editValues.name = this.value;
      this.editValues.modules = [];
      this.moduleCount = 10;
      this.isEditing = true;
      this.$refs.form.resetValidation();
    },
    saveValue() {
      if (this.$refs.form.validate()) {
        this.$emit('input', this.editValues, this.edit);
        this.isEditing = false;
      }
    },
    async deleteObject() {
      let dialogResponse = await this.$refs.confirmationDialogRef.showDialog({
        title: 'Slet lektionsplan',
        studentName: this.editValues.name,
        body: `Ønsker du at slette denne lektionsplan?`,
        okBtn: 'Slet',
      });
      if (dialogResponse) {
        this.$emit('delete');
        this.isEditing = false;
      }
      this.$refs.confirmationDialogRef.hideDialog();
    },
  },
  created() {
    this.moduleCount = 10;
  },
};
</script>

<style lang="scss">
.scrollable {
  overflow-y: auto;
  height: 35vh;
}
</style>
